.Home {
  animation-duration: 0.5s;
  animation-name: fadein;
  transition-timing-function: ease-out;
}

.HomeInner {
  width: 400px;
  max-width: 90vw;
}
